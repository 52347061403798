import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import {
	FaRulerCombined,
	FaNetworkWired,
	FaEyeSlash, // or FaSyncAlt, if you prefer
	FaSearchLocation, // or FaDatabase
	FaChartBar,
	FaSync
} from "react-icons/fa";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import OutboundLink from "src/components/mdx/OutboundLink/OutboundLink";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Branded Change Monitor",
	subTitle: (
		<>
			<p>
				This report will help you monitor and improve your branded
				SERPs.
			</p>
			<OutboundLink
				href="https://lookerstudio.google.com/reporting/b770439b-74be-4097-b40d-3e072d4861c4/"
				className={`button buttonSecondary`}
			>
				Explore anonymised report
			</OutboundLink>
		</>
	)
};

const alternatingContent = [
	{
		subTitle: "Video Walkthrough",
		content: (
			<React.Fragment>
				<p>Would you rather a short video?</p>
				<p>
					If you&apos;d like a short walkthrough of what this report
					can do we&apos;ve got one here.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<>
				<div className={`videoHolder`}>
					<iframe
						src="https://player.vimeo.com/video/909205364?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						title="piped out intro 3"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js%22%3E" />
			</>
		)
	}
];

const dashboardStrengths = [
	{
		title: "You can't measure a branded SERP with rank",
		content: (
			<React.Fragment>
				<p>
					Rank 1 should be a given. So how do you measure success for
					your branded SERPs?
				</p>
				<p>
					We measure the pixel space above the fold and on the first
					page to show what you own.
				</p>
			</React.Fragment>
		),
		icon: <FaRulerCombined />
	},
	{
		title: "Track everything you own",
		content: (
			<React.Fragment>
				<p>
					You don&apos;t just want your domain to rank, you want all
					the different properties you control to rank.
				</p>
				<p>
					Your Instagram page, LinkedIn, Wikipedia etc. We let you
					classify and track them all together.
				</p>
			</React.Fragment>
		),
		icon: <FaNetworkWired />
	},
	{
		title: "Keep an eye on negative results",
		content: (
			<React.Fragment>
				<p>
					Alongside the sites you control, you also might want to keep
					an eye on specific articles or negative results that impact
					your brand.
				</p>
				<p>
					We let you select any URLs you want and we&apos;ll keep
					track of the space they hold.
				</p>
			</React.Fragment>
		),
		icon: <FaEyeSlash />
	},
	{
		title: "Find the opportunities to control more of your branded SERP ",
		content: (
			<React.Fragment>
				<p>
					Because of domain clustering it&apos;s quite hard to
					dominate your branded SERP with just one domain.
				</p>
				<p>
					Once you&apos;re tracking multiple domains, we&apos;ll show
					you where each different domain is ranking so you can find
					opportunities to control more of the branded SERP.
				</p>
			</React.Fragment>
		),
		icon: <FaSearchLocation />
	},
	{
		title: "Track multiple SERP features",
		content: (
			<React.Fragment>
				<p>
					We won&apos;t just look at branded orgnaic results
					we&apos;ll also look at the SERP features like PPA, video
					packs, featured snippets etc.
				</p>
			</React.Fragment>
		),
		icon: <FaChartBar />
	},
	{
		title: "Automatic updates",
		content: (
			<React.Fragment>
				<p>
					Like all our dashboards the data is updated automatically
					every day.
				</p>
			</React.Fragment>
		),
		icon: <FaSync />
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images

	const title = "Brand SERP Monitoring Dashboard";
	const description =
		"Keep track of how Google displays your brand and find ways to improve how it's showing with our dashboard designed to help you pick apart this problem.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>

			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					noBotMargin={true}
				/>
			</section>
			<StripTitle title="Why use our dashboard?" />
			<StripTwoColumnIconSmall
				contentList={dashboardStrengths}
				backgroundGrey={false}
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query BrandedSERPData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg3: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/business_logic.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 866) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
